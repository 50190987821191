export default (ctx, inject) => {
  // lodash templating
  const brand = {"name":"SuperProfík","about":"O SuperProfíkovi","slogan":"Na doporučení komunity","webpage":"SuperProfík.cz","logo":{"part1":"SUPER","part2":"PROFÍK"},"fromMate":"Od našeho SuperProfík odborníka!","since":"2024","themeColor":"#337ab7","company":{"name":"JóSzaki Kft.","address":"Lágymányosi utca 12. Fsz. 2. ajtó, H-1111 Budapest, Maďarsko","registrationAuthority":"Fővárosi Törvényszék Cégbírósága","registrationNumber":"01-09-328629","taxNumber":"26495248-2-42","hosting":"Google LLC (1600 Amphitheatre Parkway Mountain View, CA 94043, USA)"}};

  const brandName =  "superprofik-cz";
  const isSuperprofikCz = brandName === "superprofik-cz";
  const isBerufExperten = brandName === "berufexperten-de";
  const isJoszaki = brandName === "joszaki";

  inject('brand', brand);
  inject('branding', {
    name: brandName,
    isSuperprofikCz,
    isBerufExperten,
    isJoszaki
  })
};