<template>
  <div class="flex flex-col items-center gap-4">
    <p class="text-center">
      {{ $t("error500.p1") }}
    </p>
    <p class="text-center">
      <i18n path="error500.p2">
        <template #supportEmail>
          <a :href="$helpers.support.emailLink">{{ $helpers.support.email }}</a>
        </template>
      </i18n>
    </p>
  </div>
</template>

<script>
export default {
  name: "ErrorInternal",
};
</script>
